<template>
  <v-switch
    v-model="themeModel"
    :label="themeModel === 'dark' ? 'Dark Mode' : 'Light Mode'"
    false-value="light"
    true-value="dark"
    hide-details
    :false-icon="mdiWhiteBalanceSunny"
    :true-icon="mdiWeatherNight"
    color="info"
    inset
  />
</template>

<script setup>
import { mdiWhiteBalanceSunny, mdiWeatherNight } from '@mdi/js';

import { useTheme } from 'vuetify';

defineOptions({
  name: `ChangeTheme`,
});

const themeModel = ref(`light`);

onMounted(() => {
  const localStorageTheme = localStorage.getItem(`theme`);
  if (localStorageTheme) {
    themeModel.value = localStorageTheme;
  } else {
    themeModel.value = window?.matchMedia?.(`(prefers-color-scheme:dark)`)
      ?.matches
      ? `dark`
      : `light`;
  }
});

const theme = useTheme();

watch(
  () => themeModel.value,
  () => {
    theme.global.name.value =
      themeModel.value === `dark` ? `customDark` : `customLight`;
    localStorage.setItem(`theme`, themeModel.value);
  },
);
</script>

<style scoped></style>
